<template>
  <div>
    <b-card title="">
      <div class="card-title">
        <div class="row">
          <div class="col-md-6">
            <h4 class="mb-0"></h4>
          </div>
          <div class="col-md-6 text-right">
            <!-- <a
              :href="`${
                themeConfig.apiUrl
              }/recipes/exports/reviews.xlsx?d=${Date.now()}`"
              class="btn btn-info btn-icon mr-1"
            >
              Export
            </a> -->
          </div>
        </div>
        <table class="table table-hover">
          <thead>
            <tr>
              <th>Recette</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="evaluation in evaluations" :key="evaluation.recipe_id">
              <td style="vertical-align: top">
                <router-link :to="`/recipes/${evaluation.recipe_id}`">
                  {{ evaluation.label }}
                </router-link>
                <table class="table table-sm small">
                  <tbody>
                    <tr
                      v-for="(result, note) in evaluation.evaluations"
                      :key="`${evaluation.recipe_id}-${note}`"
                    >
                      <td>{{ note }} étoiles</td>
                      <td style="width: 45%">
                        <el-progress
                          :stroke-width="8"
                          :percentage="parseFloat(result.percent)"
                          :status="status(parseFloat(result.percent))"
                          :show-text="false"
                        />
                      </td>
                      <td class="text-right">{{ result.percent }}%</td>
                      <td>{{ result.evaluation }} évaluation(s)</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td style="vertical-align: top" class="text-right">
                {{ evaluation.total }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
    <!-- <debug>{{ evaluations }}</debug> -->
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { BCard } from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
  data() {
    return {
      themeConfig: $themeConfig,
      displayFilters: false,
      displayForm: false,
      evaluations: {},
    }
  },
  async created() {
    this.init()
  },
  methods: {
    async init() {
      const resp = await this.$http.get('/recipes/reviews/evaluations/')
      this.evaluations = resp.data
    },
    status(percent) {
      if (percent >= 50) {
        return 'success'
      }
      if (percent >= 25) {
        return 'warning'
      }
      return 'danger'
    },
    setPage(page) {
      if (page) {
        this.filters.page = page
      }
      this.$router
        .push({
          path: this.$router.history.current.path,
          query: this.filters,
        })
        .catch((error) => {
          if (error.name !== 'NavigationDuplicated') {
            // throw error
            console.log(error)
          }
        })
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    },
    onCreate(note) {
      this.displayForm = false
      this.$router.push(`/notes/${note.id}/`)
    },
  },
}
</script>

<style></style>
